<template>
  <!-- Generated using model-view-preview.mustache [ DO NOT EDIT GENERATED FILES DIRECTLY! ] -->
  <div class="sqsConnection-detail">
    <div class="form-content content">
      <ActionsForPreview
        :loading="loading"
        @edit="editSqsConnection"
        @delete="openModal"
      />
      <div v-if="ready">
        <CRow>
          <CCol col="12">
            <CCard class="zq--wizard-card" v-if="ready">
              <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
                <div class="d-flex justify-content-between">
                  <strong class="title">{{ texts.previewPage.title }}</strong>
                  <CLink class="card-header-action btn-minimize">
                    <ClCardArrow :is-collapsed="basicIsCollapsed" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="basicIsCollapsed" :duration="400">
                <CCardBody>
                  <ZqFieldsView :texts="texts.previewPage" :formData="formData" :modelFields="modelFields" />
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCol>
        </CRow>
        <ViewRules
          v-if="ruleModels.includes(model)"
          :entityId="entityId"
        />
        <ViewDependantOn
          v-if="formData.dependantOn && Object.keys(formData.dependantOn).length"
          :dependantOnData="formData.dependantOn"
        />
        <ViewScheduling
          v-if="schedulingData"
          :schedulingData="schedulingData"
        />
        <ViewTranslations
          v-if="modelFields.baseFields.translatableFields.length > 0"
          :entityData="formData"
          :entityId="formData.id"
        />
        <ViewRewards :entityId="entityId" v-if="rewardModels.includes(model)"/>
      </div>
      <PreviewSpiner v-else />
    </div>
    <Modal
      :modalShow="deleteModal"
      :messageGeneral="texts.deleteMessage"
      :title="texts.deleteTitle"
      @doFunction="deleteSqsConnection"
      v-on:toggle-modal="deleteModal = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ActionsForPreview from '@/shared/components/ActionsForPreview';
import CIMetaDataView from '@/shared/components/CIMetaDataView';
import ZqCardRow from '@/shared/UI/ZqCardRow';
import ZqFieldsView from '@/shared/components/ZqFieldsView';
import ClCardArrow from '@/shared/UI/ClCardArrow';
import { pageConfig } from '@/config';
import PreviewSpiner from '@/shared/UI/Spiner';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import Modal from '@/shared/UI/Modal';
import { sqsConnectionsTexts } from '@/config/pageTexts/sqsConnections.json';
import fields from '@/generated/ziqni/store/modules/sqsConnections/fields';
import ViewTranslations from '@/shared/components/supportModels/translations/ViewTranslations';
import ViewRules from '@/shared/components/supportModels/rules/ViewRules';
import ViewDependantOn from '@/shared/components/supportModels/dependantOn/ViewDependantOn';
import ViewScheduling from '@/shared/components/supportModels/scheduling/ViewScheduling';
import ViewRewards from '@/shared/components/supportModels/rewards/ViewRewards';
export default {
  name: 'SqsConnectionDetails',
  components: {
    PreviewSpiner,
    ActionsForPreview,
    ZqFieldsView,
    ZqCardRow,
    Modal,
    CIMetaDataView,
    ClCardArrow,
    ViewTranslations,
    ViewRules,
    ViewDependantOn,
    ViewScheduling,
    ViewRewards,
  },
  data() {
    return {
      model: 'sqsConnection',
      entityId: this.$route.params.id,
      formData: {
        id: '',
        spaceName: '',
        created: '',
        customFields: {},
        tags: [],
        metadata: {},
        name: '',
        description: '',
        uri: '',
        acessKey: '',
        lastKnownStatus: '',
        lastKnownStatusCode: '',
        transformerId: '',
        connectionType: ''
      },
      texts: {
        ...sqsConnectionsTexts,
      },
      basicIsCollapsed: true,
      inboxIsCollapsed: false,
      deleteModal: false,
      itemsPerPage: pageConfig.itemsPerPage,
      query: {},
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
      modelFields : {
        ...fields,
      },
      ready: false,
      schedulingData: null,
      rewardModels: ['achievement'],
      ruleModels: ['achievement'],
    };
  },
  computed: {
    ...mapGetters('sqsConnections', [
      'success',
      'message',
      'sqsConnections',
      'loading',
      'sqsConnection'
    ]),
    ...mapGetters('theme', ['theme']),
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('sqsConnections', [
      'handleGetSqsConnections_item',
      'handleDeleteSqsConnections',
    ]),
    ...mapActions('connections', [
      'handleGetConnections',
    ]),
    async initialize() {
      await this.handleGetConnections([this.$route.params.id], 1, 0).then((data) => {
        routerBreadcrumbs(this.$router.currentRoute, {
          name: data[0].name,
        });
        if (data[0].scheduling) {
          this.schedulingData = data[0].scheduling;
        }
        this.ready = true;
      });
    },
    editSqsConnection() {
      this.$router.push({
        name: 'EditSqsConnection',
        params: {
          id: this.formData.id,
          name: this.formData.name,
        },
      });
    },
    deleteSqsConnection() {
      this.deleteModal = false;
      this.handleDeleteSqsConnections({idArray: [this.formData.id]})
        .then(() => this.$router.push({ name: 'Connections' }))
        .catch((e) => {console.log(e.message)});
    },
    openModal() {
      this.deleteModal = true;
    },
  },
  watch: {
    sqsConnection: {
      deep: true,
      handler: function (val) {
        this.formData = val;
      },
    },
    sqsConnections: {
      deep: true,
      handler: function () {
        this.$router.push({ path: '/sqsConnections' }).catch((e) => {
          console.log(e.message);
        });
      },
    },
  },
};
</script>
<style lang="scss">
sqsConnection-detail {
  height: 100%;
  flex: 2;
  .content {
    border-top: 1px solid var(--zq-content-border-color);
  }
  .form-content {
    height: calc(100% - 50px);
    background: var(--zq-sub-bg);
  }
  .lang-custom-button {
    width: 100%;
  }
}

.detail-label {
  @media (min-width: 420px) {
    max-width: 220px;
  }
}

.overview-label {
  font-style: italic;
  color: darkgray;
}
</style>
