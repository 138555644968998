<template>
  <ul v-if="metadata">
    <li v-for="(meta, index) in Object.keys(metadata)" :key="index">
      <span>{{ meta }} &nbsp;:</span>&nbsp;
      <span>{{ metadata[meta] }}</span>
      <br />
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    metadata: [Object, Array],
  },
};
</script>
